<template>
    <section class="homevideo">
        <div class="homevideo__container">
            <div class="homevideo__background">
                <div class="homevideo__background__video">
                    <figure class="homevideo__background__asset">
                        <video 
                            autoplay
                            class="video"
                            data-load="objectFit"
                            loop
                            muted
                            playsinline>
                            <source data-src="https://api.uptowncreative.io/uploads/_/assets/chadrogers/cerda-zein-video.mp4" type="video/mp4" src="https://api.uptowncreative.io/uploads/_/assets/chadrogers/cerda-zein-video.mp4">
                        </video>
                    </figure>
                </div>
                <div class="homevideo__background__mobile">
                    <figure class="homevideo__background__asset">
                        <img alt="Cerda Zein"  src="/images/frame-1.jpg">
                    </figure>
                </div>
            </div>
            <div class="homevideo__gradient"></div>
            <div class="homevideo__foreground">
                <div class="homevideo__content">
                    <h1 v-html="$t(`message.home.heroTitle`)">
                    </h1>
                    <div class="button_group">
                        <a aria-label="Go to instant home value page" href="https://www.cerdazeinrealestate.com/cma/property-valuation/custom/" target="_blank">{{$t(`message.home.instantHomeValue`)}}</a>
                        <router-link to="/properties">{{$t(`message.home.propertyMap`)}}</router-link>
                    </div>
                    <img class="arrow" src="images/d_arrow.png" alt="arrow Down" />
                </div>
                <div class="scrolldown"><a aria-label="Go to featured listings section" href="#featured-listings" class="btn arrow bounce"></a></div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.homevideo{
    &__container{
        height: 100vh;
        width: 100%;
        min-height: 480px;
    }
    &__background{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        &__video{
            display:block;
            &.isloading{
                background: url("/images/frame-1.jpg") cover;
            }
        }
        &__asset{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            .video{
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__mobile{
            display: none;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    &__gradient{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, #000, #000);
        opacity: 0.000001;
    }
    &__foreground{
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
    }
    h1{
        font-size: 3.75rem;
        color: #fff;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
    }
}
figure{
    padding: 0;
    margin: 0;
}
.arrow{
    bottom: 0;
    width: 39px;
    height: 24px;
    background: url(/images/dn-arrow.png) no-repeat 50%;
}
.bounce{
    animation: bounce 2s infinite;
}
@keyframes bounce {
    0% {
        transform: translateY(-45px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

.scrolldown{
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
    border: none;
}
.btn{
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 10px 0;
    padding: 0 15px;
    height: 42px;
    outline: none;
    border: 2px solid #fff;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.25rem;
    line-height: 40px;
    transition: color .3s;
    border: none;
}

.homevideo__content{
    top: unset !important;
    left: unset !important;
    transform: unset !important;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(16,16,16,0.35) 100%, rgba(16, 16, 16, 0.35) 100%);
    text-align: center;
}

.homevideo__content h1{
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    color: #fff;
    font-weight: 200;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
    font-size: 3rem;
}

.homevideo__content .button_group{
    top: 70%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    display: flex;
}

.homevideo__content .button_group a{
    display: inline-block;
    width: 190px;
    margin: 16px 10px;
    background-color: rgba(255, 255, 255, 0);
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #fff;
    letter-spacing: 1.5px;
    text-decoration: none;
    font-size: 0.75rem;
    padding:10.5px 12px;
    border: 1px solid #fff;
}
.homevideo__content .button_group a:hover{
    cursor:pointer;
    opacity: 0.8;
}

.homevideo__content p{
    position: absolute;
    transform: translate(-50%,-50%);
    right: 10px;
    bottom: 10px;
    color: #fff;
    padding: 8px 16px;
    background: linear-gradient(90deg, rgba(16,16,16,0.3) 100%, rgba(16, 16, 16, 0.3) 100%);
}

.homevideo__content .arrow{
    position: absolute;
    top: 75%;
    width: auto;
    height: 100px;
    opacity: 0.7;
    left: calc(50% - 10px);
}

@media (max-width: $tablet) {
    .homevideo{
        &__foreground{
            h1{
                line-height: 1;
                margin-left: 32px;
                font-size: 2.8125rem;
            }
            p{
                margin-left: 32px;
            }
        }
    }
}

@media (max-width: 641px) {
    .homevideo__content .arrow{
        display: none;
    }
    .homevideo__content h1{
        top: 45%;
        font-size: 2.5rem;
        margin: 0 16px;
    }

    .homevideo__content .button_group{
        flex-wrap: wrap;
        top: 65%;
    }
    .homevideo__content .button_group a{
        margin: 20px 10px;
    }
}
.arrow{
    position: absolute;
    bottom: 10px;
    width: auto;
    height: 70px;
    opacity: 0.7;
    left: 50%;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), not all, only screen and (min-resolution: 192dpi){
    .arrow{
        background-image: url(/images/dn-arrow-2x.png);
        background-size: contain;
    }
}
</style>

<script>
export default {
    name:"HomeVideo",
    data(){
        return{
            isloading: true,
            title: "It's always a beautiful day in the neighborhood",
        }
    }
}
</script>